import showLoading from '@/helper/loading'
import { MarkcoinRequestConfig } from '@/plugins/request'

const onFulfilled = (config: MarkcoinRequestConfig) => {
  if (config.loading) {
    const { close } = showLoading()
    config.closeLoading = close
  }

  return config
}

const onRejected = error => {
  if (error?.config?.loading) {
    error.config.closeLoading?.()
  }
  return Promise.reject(error)
}

export default { onFulfilled, onRejected }
