import cacheUtils from 'store'

export const userInfo = 'USER_INFO'

export function getUserInfo() {
  return cacheUtils.get(userInfo)
}

export function setUserInfo(val) {
  return cacheUtils.set(userInfo, val)
}

export function removeUserInfo() {
  return cacheUtils.set(userInfo, '')
}

/** C2C 公有化用户信息 */
export const C2C_USER_INFO = 'C2C_USER_INFO'

export function getC2CUserInfoCache() {
  return cacheUtils.get(C2C_USER_INFO)
}

export function setC2CUserInfoCache(data) {
  return cacheUtils.set(C2C_USER_INFO, data)
}
