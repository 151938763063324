/**
 * 公共数据字典类型
 */
export enum CommonDictionaryTypeEnum {
  /** 语言类型 */
  languageType = 'lan_type_cd',
  /** 支付类型 支付宝/微信支付/银行卡（c2c、法币提现） */
  paymentType = 'c2c_payment_type_cd',
  /** 支付方式颜色（c2c、法币提现） */
  paymentColor = 'c2c_payment_color',
  /** 支付备注类型（c2c、法币提现） */
  paymentRemark = 'c2c_payment_remark',
  /** 支付方式内容（c2c、法币提现） */
  paymentSupportItems = 'c2c_payment_support_items',
}

export enum AdCodeDictionaryEnum {
  Advert_Direct = 'advert_buy_sell',
  Deal_Type = 'c2c_advert_deal_type',
  Payment_Type = 'c2c_payment_type_cd',
  Ad_Status = 'c2c_advertising_status_cd',
  Payment_Color = 'c2c_payment_color',
  Payment_Remark = 'c2c_payment_remark',
  PaymentSupportItems = 'c2c_payment_support_items',
}
