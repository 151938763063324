import { c2cModeEnum } from '@/constants/c2c/common'
import { envUtils } from '@nbit/utils'
import { InitThemeColor } from '@/constants/base'

const { EnvTypesEnum, getEnvAwsS3Config } = envUtils

/** 获取是否是 NodeJs 服务器环境 */
export const envIsServer = import.meta.env.SSR
/** 获取是否是客户端环境 */
export const envIsClient = !envIsServer
/** 是否是开发环境 */
export const envIsDev = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.development
/** 是否是测试环境 */
export const envIsTest = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.test
/** 是否是 sg dev 环境 */
export const envIsSGDev = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.dev

export const envIsBuild = !envIsDev

/** 迁移 */
export const baseUrl = envIsClient
  ? import.meta.env.VITE_MARKCOIN_BASE_URL
  : import.meta.env.VITE_MARKCOIN_SERVER_BASE_URL
export const otcUrl = import.meta.env.VITE_MARKCOIN_WS /** 迁移 */
export const swapUrl = import.meta.env.VITE_MARKCOIN_WS /** 迁移 */

// 合约 ws
export const wsFuturesUrl = import.meta.env.VITE_MARKCOIN_WS_CONTRACT /** 迁移 */
// 期权 ws
export const wsOptionUrl = import.meta.env.VITE_MARKCOIN_WS_OPTION || wsFuturesUrl /** 迁移 */
// 现货 ws
export const wsUrl = import.meta.env.VITE_MARKCOIN_WS /** 迁移 */

export const port = import.meta.env.VITE_PORT
// git  最近的 id
export const gitCommitId = import.meta.env.VITE_GIT_COMMIT_ID

/** AWS S3 config */
export const awsS3Config = getEnvAwsS3Config(import.meta.env.VITE_NEWBIT_ENV)
export const newbitEnv = import.meta.env.VITE_NEWBIT_ENV

export const businessId = import.meta.env.VITE_MARKCOIN_BUSINESS_ID /** 迁移 */
export const H5Url = import.meta.env.VITE_MARKCOIN_H5_URL
export const WebUrl = import.meta.env.VITE_MARKCOIN_WEB_URL
/** 娱乐区 url */
export const RecreationWebUrl = import.meta.env.VITE_MARKCOIN_RECREATION_WEB

export const ModuleConfig = import.meta.env.VITE_MARKCOIN_MODULE_CONFIG /** 迁移 */

export const templateId = import.meta.env.VITE_MARKCOIN_TEMPLATE_ID /** 迁移 */
export const saasId = import.meta.env.VITE_MARKCOIN_SAAS /** 迁移 */

// monkey business user
export const monkeyBid = '1'
export const isMonkey = businessId === monkeyBid

/** 是否是生产环境 */
export const envIsProd = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.production

// s3 中的主题色
export const systemThemeColor =
  InitThemeColor[import.meta.env.VITE_MARKCOIN_DEFAULT_THEME] ||
  (isMonkey ? InitThemeColor.mk : InitThemeColor.cs) /** 迁移 */

/** c2c mode */
export const c2cMode = import.meta.env.VITE_MARKCOIN_C2C_MODE /** 迁移 */
export const isPublicC2cMode = c2cMode === c2cModeEnum.public /** 迁移 */
export const publicC2cBaseUrl = import.meta.env?.VITE_MARKCOIN_PUBLIC_C2C_BASE_URL /** 迁移 */
export const publicC2cWs = import.meta.env?.VITE_MARKCOIN_PUBLIC_C2C_WS /** 迁移 */

export const imRequest = import.meta.env.VITE_IM_REQUEST_ADDRESS /** 迁移 */
export const imRouter = import.meta.env.VITE_IM_ROUTER /** 迁移 */
export const c2cSdkAppId = Number(import.meta.env.VITE_MARKCOIN_C2C_SDK_APP_ID || '1024650793') /** 迁移 */
