import { fastUrlUtils } from '@nbit/utils'
import { baseCommonStore } from '@/store/common'
import { envIsProd, isMonkey, businessId, systemThemeColor } from '@/helper/env'
import { InitThemeColor } from './base'

const { getFastUrl } = fastUrlUtils

export const ossDomain = getFastUrl('https://markcoin-oss.oss-ap-southeast-1.aliyuncs.com')

export const monkeyOssConfig = {
  iconFontFile: 'icon_mk.js',
  ossFolder: 'mk',
}

export const chainStarOssConfig = {
  iconFontFile: 'icon_cs.js',
  ossFolder: 'cs',
}

export const fusionModeOssConfig = {
  iconFontFile: 'icon_ok.js',
  ossFolder: 'ok',
}

export const getOssConfig = () => {
  switch (systemThemeColor) {
    case InitThemeColor.cs:
      return chainStarOssConfig
    case InitThemeColor.ok:
      return fusionModeOssConfig
    case InitThemeColor.mk:
    default:
      return monkeyOssConfig
  }
}

const { iconFontFile, ossFolder } = getOssConfig()

/** 公共 Icon OSS 地址 */
const getOssIconAddress = () => {
  const environmentPrefix = envIsProd ? 'prod' : 'dev'
  return `${ossDomain}/common/icon/ex/web/${environmentPrefix}`
}

/**
 * 公共 OSS image 地址
 * @param type 文件类型:image/json
 * @param isHasDomain 是否需要域名
 * @returns
 */
const getOssFolderAddress = (type = 'image', isHasDomain = true) => {
  let url = `/common/${type}/ex/web/${ossFolder}/`
  if (isHasDomain) url = `${ossDomain}${url}`
  return url
}

/**
 * 公共 OSS 区分商户的 image 地址
 * @param type 文件类型:image/json
 * @param isHasDomain 是否需要域名
 * @returns
 */
const getOssManyBusinessImageAddress = (type = 'image', isHasDomain = true) => {
  let url = `/ex/nb/web/${type}/business/${businessId}/`
  if (isHasDomain) url = `${ossDomain}${url}`
  return url
}

export const getIsManyMerchantMode = () => {
  const { isMergeMode } = baseCommonStore.getState()
  // is chainstar and not fusion mode === many merchant mode
  return !isMergeMode && !isMonkey
}

/** web OSS 地址，公共文件地址 */
export const oss_domain_address = `${ossDomain}/common`

/** web OSS 非渐变色 svg 地址 */
export const oss_svg_domain_address = `${getOssIconAddress()}/${iconFontFile}`

/** web OSS 渐变色 svg 地址 */
export const oss_svg_image_domain_address = getOssFolderAddress('image')

/** web OSS json 地址 */
export const oss_json_domain_address = getOssFolderAddress('json')

/** 代理商图片地址 */
export const oss_svg_image_domain_address_agent = `${getOssFolderAddress('image')}agent/`

/** 国家国旗图片 png 地址 */
export const oss_area_code_image_domain_address = `${ossDomain}/common/area_code_img/`

/** 融合模式图片地址 */
export const oss_fusion_mode_image_domain_address = `${getOssFolderAddress('image')}merge_mode/`

/** 融合模式 svg 地址 */
export const oss_fusion_mode_svg_domain_address = `${getOssIconAddress()}/${fusionModeOssConfig.iconFontFile}`

/**
 * url 传进来的地址
 * whetherManyBusiness: 是否是多商户模式图片
 * businessId: 商户 id
 */
export function getFastManyBusinessUrl(url: string, whetherManyBusiness?: boolean) {
  /** 是否是多商户模式图片，如果是就会替换想换路径 * */
  if (whetherManyBusiness) {
    url = url.replace(getOssFolderAddress('image', false), getOssManyBusinessImageAddress('image', false))
  }
  return url
}
