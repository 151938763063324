import cs from 'classnames'
import LoadingElement from '../loading-element'

import styles from './index.module.css'

export interface IFullScreenLoadingProps {
  /** loading内容 */
  loadingEle?: React.ReactNode
  /** loading遮罩z-index */
  zIndex?: number
  className?: string
  style?: React.CSSProperties
}

/** 默认loading动画 */
const defaultLoadingEle = <LoadingElement />

function FullScreenLoading({
  loadingEle = defaultLoadingEle,
  className,
  style,
  zIndex = 9999999,
}: IFullScreenLoadingProps) {
  const wrapperClassName = cs(styles['global-fullscreen-loading'], className)
  const wrapperStyle: React.CSSProperties = {
    ...style,
    zIndex,
  }
  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      {loadingEle}
    </div>
  )
}

export default FullScreenLoading
