import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { replaceEmpty } from './filters'

export enum DateFormatTemplate {
  default = 'YYYY-MM-DD HH:mm:ss',
}
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
/**
 * 格式化时间，默认格式为 YYYY-MM-DD HH:mm:SS
 * @param date 格式化前的时间
 * @param template 格式化模板
 * @param isUTC 是否 0 时区，默认 true
 * @returns 格式化后的时间
 */
export function formatDate(date: string | number, template: string = DateFormatTemplate.default, isUTC = true) {
  if (!date) {
    return replaceEmpty(date)
  }
  return dayjs(date).format(template)
}

/**
 * 用于计算倒计时展示的时间所需要的方法，这种情况不能用 formatDate，例如中国大陆时区和新加坡时区，相差半个小时，会导致倒计时往后延迟 30 分钟
 *
 */
export function formatUtcDate(date: string | number, template: string = DateFormatTemplate.default) {
  if (!date) {
    return replaceEmpty(date)
  }
  return dayjs(date).utc().format(template)
}

const DAY_MS = 24 * 60 * 60 * 1000
/**
 * 获取天数的毫秒数
 * @param day 天数
 * @returns 毫秒数
 */
export function getDayMs(day: number) {
  return day * DAY_MS
}
/** 展示时分秒时填充前置 0 */
export function fillZero(num: number) {
  return num < 10 ? `0${num}` : num
}
/** 获取从今天往前天数的开始时间，比如 1 天，是今天凌晨到今天 23:59:59  */
export function getPeriodDayTime(days: number) {
  const start = dayjs(formatDate(Date.now(), 'YYYY-MM-DD')).toDate().getTime() - getDayMs(days - 1)
  const end = start + getDayMs(days) - 1

  return {
    start,
    end,
  }
}

/**
 *  获取合约资金费率下一个结算日期
 * @param times '0,6,8'
 * @param span 间隔，如 8
 */
export function getFutureFundingRateNextDate(times: string, span: number) {
  let targetDate = dayjs().tz('Asia/Shanghai').toDate()
  targetDate.setHours(Number(times[0] || 0), 0, 0, 0)
  while (targetDate.getTime() < Date.now()) {
    targetDate = new Date(targetDate.getTime() + 60 * 60 * 1000 * (span || 8))
  }

  return targetDate?.getTime()
}
/**
 * 获取当前的年月日时间戳
 */
export function getCurrentFullDayTimestamp() {
  return dayjs(formatDate(Date.now(), 'YYYY-MM-DD')).toDate().getTime()
}

// 获取指定时间段开始时间
export function getBeforeDate(n: number | false): number {
  if (!n) {
    return null as any
  }

  let oneDay = 24 * 60 * 60 * 1000
  const startTime = new Date(new Date(new Date(Date.now() - n * oneDay).getTime()).setHours(0, 0, 0, 0)).getTime()
  return startTime
}
/**
 * 获取客户端时区
 * @returns eg: +08:00
 */
export function getTimeZoneOffset() {
  return `UTC${dayjs().format('Z')}`
}

/**
 * 获取后 1 天的年月日时间戳
 */
export function getNextFullDayTimestamp() {
  let tomorrow = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 + 1
  return dayjs(formatDate(tomorrow, 'YYYY-MM-DD')).toDate().getTime()
}

/**
 * 根据返回的秒数折算成天时
 * @param seconds 秒
 * @param isHours 是否需要折算到小时
 * @param isMinutes 是否需要折算到分钟
 */
export function formatSeconds(seconds, isHours = true, isMinutes = false) {
  if (isNaN(seconds) || seconds < 0) {
    return '--'
  }

  const days = Math.floor(seconds / (24 * 60 * 60))
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((seconds % (60 * 60)) / 60)

  let result = `${days}天`

  if (isHours) {
    result += `${hours}时`
  }

  if (isMinutes) {
    result += `${minutes}分`
  }

  return result
}

// 秒数转天时
export const formatSecondsToDHMS = totalSeconds => {
  if (!totalSeconds) return 0
  const secondsPerMinute = 60
  const secondsPerHour = 60 * secondsPerMinute
  const secondsPerDay = 24 * secondsPerHour
  const days = Math.floor(totalSeconds / secondsPerDay)
  const hours = Math.ceil((totalSeconds % secondsPerDay) / secondsPerHour)
  const formattedTime = `${days}天 ${hours}时`

  return formattedTime
}

/**
 * 获取两日间相差秒
 */
export function getSecondsBetweenTwoDays(timestamp2) {
  let today = dayjs(formatDate(Date.now(), 'YYYY-MM-DD HH:mm:ss')).toDate().getTime()
  let timeDiffInSeconds = Math.abs(timestamp2 - today) // 获取两个日期之间的秒数
  return timeDiffInSeconds
}

/**
 * 获取两日间相差天/时/分
 */
export function getTimeBetweenTwoDays(timestamp2) {
  let seconds = getSecondsBetweenTwoDays(timestamp2) / 1000
  let times = 0
  const days = Math.floor(seconds / (24 * 60 * 60))
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((seconds % (60 * 60)) / 60)

  if (minutes > 0) {
    times = minutes
  }
  if (hours > 0) {
    times = hours
  }
  if (days > 0) {
    times = days
  }
  return times
}

/**
 * 获取所传时间时间戳
 */
export function getTimestamp(date: string) {
  // 兼容 safari
  const dateObject = new Date(date.replace(/-/g, '/'))
  // 使用 getTime() 方法获取该日期对象的时间戳
  const timestamp = dateObject.getTime()
  return timestamp
}

/**
 * 比较两个时间戳所对应的日期是否相同
 */
export function isSameDay(timestamp1, timestamp2) {
  const date1 = new Date(Number(timestamp1))
  const date2 = new Date(Number(timestamp2))

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

/**
 * 判断当前日期是否是当天
 */
export function isToday(date: string) {
  return dayjs().isSame(date, 'day')
}
