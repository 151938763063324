/**
 * 法币 - 冲提
 */
export enum TabType {
  // 充值
  charge = 'charge',
  // 提现
  withdraw = 'withdraw',
}

export enum PaymentManagement {
  // 当面现金交易
  FaceToFace = 'FaceToFace',
}

/** 法币收付款方式字典值 */
export enum PaymentDicCodeEnum {
  /** 支付方式字典 */
  paymentDicCode = 'pay_method_cd',
}

/**
 * 法币充值相关接口路径（不需要关联 c2c 公有化）
 */
export const LegalCurrencyRechargeApiList = [
  '/v1/c2c/pay/charge/info',
  '/v1/c2c/pay/fiat/currency',
  '/v1/c2c/pay/withdraw/info',
  '/v1/c2c/pay/charge',
  '/v1/c2c/pay/withdraw',
  '/v1/c2c/pay/footer',
  '/v/c2c/pay/default/currency',
  '/v1/c2c/pay/pre-withdraw',
  '/v1/c2c/commonSetting/trade/channel',
  '/v1/c2c/pay/default/fiat/currency',
  '/v1/c2c/pay/rate',
]

export enum FiatWithdrawCodeTypeEnum {
  // 账户余额不足
  balance = 'USER_BALANCE__NOT_ENOUGH',
  // 交易超过 KYC 交易上限
  tradeOver = 'TRADE_AMOUNT_OVER_KYC_LIMIT',
  // 需要 KYC 认证
  kYCRequired = 'NEED_KYC_AUTHORIZED',
  // 提现数量超过限制
  withdrawalExceedLimit = 'WITHDRAW_OVER_LIMIT',
  // 修改密码再 24 小时之内不能提现
  resetPwdLimit = 'RESET_PWD_LIMIT',
  // 修改密码再 48 小时之内不能提现
  resetSafeLimit = 'RESET_SAFE_LIMIT',
  // 通过
  pass = 'PASS',
}
