import { t } from '@lingui/macro'

/**
 * 合约交易模式
 */
export enum FuturesTradeModeEnum {
  /** 普通模式 */
  common = 'common',
  /** 带单模式 */
  copyTrading = 'copyTrading',
  /** 跟单 (兼容跟单当前主页跟单委托) */
  followTrading = 'follow',
}

/**
 * 获取合约交易模式名称
 */
export const getFuturesTradeModeName = (mode: string) => {
  return {
    [FuturesTradeModeEnum.common]: t`constants_copy_trading_common_2fhd78ekeu`,
    [FuturesTradeModeEnum.copyTrading]: t`constants_copy_trading_common_wi3gdlloo2`,
  }[mode]
}

/**
 * 合约账户类型，PS: 值不要随意改，映射了接口入参
 */
export enum FuturesTradeAccountTypeEnum {
  /** 普通合约账户 */
  futuresAccount = 'normal',
  /** 带单账户 */
  takerAccount = 'taker',
  /** 跟单账户 */
  followerAccount = 'follower',
}

/**
 * 合约资产划转类型
 */
export enum FuturesTransferAccountTypeEnum {
  /** 带单/跟单子账户 */
  subAccount = 'subAccount',
  /** 普通合约账户、带单/跟单主账户 */
  mainAccount = 'mainAccount',
}
