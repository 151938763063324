const onFulfilled = response => {
  if (response.config?.loading) {
    response.config.closeLoading?.()
  }

  return response
}

const onRejected = error => {
  if (error?.config?.loading) {
    error.config.closeLoading?.()
  }
  return Promise.reject(error)
}

export default { onFulfilled, onRejected }
