import { baseLayoutStore } from '@/store/layout'
import { link } from '../link'
import { getIsLogin } from '../auth'

/** maintenance page */
export function getMaintenanceRoutePath() {
  return '/maintenance'
}

export function getRecreationPageRoutePath() {
  return `/recreation`
}

/** 联系客服 */
export function linkToCustomerService() {
  const url = baseLayoutStore.getState().layoutProps?.customerJumpUrl
  link(url, { target: true })
}

export function linkWithLoginCheck(path: string) {
  if (getIsLogin()) {
    return link(path)
  }

  return link(`login?redirect=${path}`)
}
