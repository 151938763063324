import { ErrorTypeEnum } from '@/constants/assets'
import { RiskControlTypeEnum } from '@/constants/risk-control'
import { IRiskControlData } from '@/plugins/request'
import { baseCommonStore } from '@/store/common'
import { Message } from '@nbit/arco'

// 监听风控 lv1 弹框隐藏状态
export async function waitRiskErrorPopupClose(riskCode?: string) {
  if (riskCode !== RiskControlTypeEnum.riskControlLv1) return

  return new Promise<void>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout
    const timeTotal = 2000

    const checkValue = async () => {
      const { riskControlData } = baseCommonStore.getState()

      if (riskControlData?.code !== RiskControlTypeEnum.riskControlLv1) {
        clearTimeout(timeoutId)
        resolve()
      } else {
        // 如果需要等待条件满足，就继续设置新的计时器
        timeoutId = setTimeout(checkValue, timeTotal)
      }
    }

    timeoutId = setTimeout(checkValue, timeTotal) // 初始化计时器
  })
}

/** 处理风控状态码 */
export const handleRiskErrorCode = async (riskControl?: IRiskControlData) => {
  const { code, msg, forbidList } = riskControl || {}
  if (!code) return

  const { setRiskControlData } = baseCommonStore.getState()
  setRiskControlData({ code, msg, forbidList })

  // 无权限风控
  if (code === RiskControlTypeEnum.riskControlLv3) {
    Message.error({
      content: msg || 'risk control error',
      id: ErrorTypeEnum.riskControlError,
    })
    return
  }

  // lv1 等待风控弹窗消失再往下执行
  await waitRiskErrorPopupClose(riskControl?.code)
}
