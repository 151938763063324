/**
 * 风控&标签配置表，此文件为从后端整理出的异常错误码
 */
/** 风控级别代码 */
export enum RiskControlTypeEnum {
  /** 风控 1 级 - 弹框告警 */
  riskControlLv1 = 'wind_control_lv1',
  /** 风控 2 级 - 403 页面提示 - 禁止访问 */
  riskControlLv2 = 'wind_control_lv2',
  /** 风控 3 级 - toast 提示无权限 */
  riskControlLv3 = 'wind_control_lv3',
}

/** 风控事件编码，记录便于排查问题 */
export enum RiskControlCodeEnum {
  /** ------- wind_control_lv3 风控 - 403 页面提示 ------- */
  /** 全站访问 - 无权限 */
  loginExit = 50000001,
  /** 营销活动入口 - 无权限 */
  marketingDisable = 50000007,
  /** 期权入口 - 无权限 */
  optionProhibitAccess = 50000013,
  /** KYC 认证入口 - 无权限 */
  kycToOrders = 50000016,
  /** ------- wind_control_lv2 风控 - Tost 提示 ------- */
  /** 登录按钮 - 禁止登录  */
  loginDisable = 50000002,
  /** 注册按钮 - 禁止注册  */
  registerDisable = 50000004,
  /** 提币按钮 - 禁止提币  */
  payDisable = 50000006,
  /** 期权下单 - 禁止下单  */
  optionNoToOrders = 50000015,
  /** KYC - 禁止认证 */
  kycDisable = 50000017,
  /** ------- wind_control_lv1 风控 - 弹框提示 ------- */
  /** 登录按钮  */
  loginPopup = 50000003,
  /** 注册按钮  */
  registerPopup = 50000005,
  /** 营销活动入口 - 弹窗警告  */
  marketingPopup = 50000008,
  /** 期权入口 - 弹窗警告  */
  optionPopup = 50000011,
  /** 期权下单 - 弹窗警告  */
  optionBuyPopup = 50000014,
  /** KYC - 禁止认证 - 币对大作战弹窗警告 */
  kycPopup = 50000018,
  /** ------- 风控功能屏蔽 - 入口隐藏 ------- */
  /** 期权功能屏蔽  */
  optionHide = 50000012,
}
