import { maintenanceApiResCode } from '@/constants/maintenance'
import { envIsServer } from '@/helper/env'
import { getMaintenanceRoutePath } from '@/helper/route/common'
import { baseCommonStore } from '@/store/common'
import { navigate } from 'vite-plugin-ssr/client/router'

const maintenanceCode = [438]

const onFulfilled = (input: any) => {
  if (envIsServer) return input
  const store = baseCommonStore.getState()
  if (input.data.code === maintenanceApiResCode) store.setMaintenanceMode({ triggerCheck: true })
  else store.setMaintenanceMode({ triggerCheck: false })
  return input
}

const onRejected = error => {
  if (maintenanceCode.includes(error.response?.status) && error.response?.data?.code === maintenanceApiResCode) {
    navigate(getMaintenanceRoutePath())
  }
  return Promise.reject(error)
}

export default {
  onFulfilled,
  onRejected,
}
