/** 广告展示位置 */
export enum AdsPositionCdEnum {
  /** 首页 */
  homePage = '/',
  /** 个人中心 */
  personalPage = 'personal-center',
  /** 身份认证 */
  identityPage = '/kyc-authentication-homepage',
  /** 资产 */
  assetPage = 'assets',
  /** 一键买币 */
  c2cQuickPage = '/c2c/fast-trade',
}

/** 广告展示位置映射 key */
export enum AdsPositionCdKeyEnum {
  /** 首页 */
  homePage = 'home_page',
  /** 个人中心 */
  personalPage = 'personal_page',
  /** 身份认证 */
  identityPage = 'identity_page',
  /** 资产 */
  assetPage = 'asset_page',
  /** 一键买币 */
  c2cQuickPage = 'c2c_quick_page',
}

/** 目标用户类型 */
export enum AdsTargetUserTypeEnum {
  /** 所有用户 */
  all = 'all',
  /** 未完成 KYC 认证 */
  notKyc = 'not_kyc',
  /** 区域代理用户等级 */
  area = 'area',
  /** 金字塔代理用户 */
  pyramid = 'pyramid',
  /** 三级代理用户等级 */
  threeLevel = 'threeLevel',
  /** VIP 用户等级 */
  vip = 'vip',
}

/** 展示样式类型 */
export enum AdsStyleCdEnum {
  /** 独立弹窗 */
  singlePopup = 'single_popup',
  /** 复合弹窗 */
  multiPopup = 'multi_popup',
  /** 浮窗 */
  floatingWindow = 'floating_window',
  /** 气泡标签 */
  bubbleTag = 'bubble_tag',
  /** Banner */
  banner = 'banner',
}

/** 内链字典值 */
export enum TerminalRouteUrlEnum {
  /** 我的任务 - 成就任务	*/
  missionList = '/welfare-center?welfareTab=MyTasks',
  /** 我的任务 - 挑战任务 */
  missionAchievement = '/welfare-center?welfareTab=MyTasks',
  /** 我的奖励 */
  awardList = '/welfare-center?welfareTab=CardVoucherCenter',
  /** 我的活动 */
  activityProcessing = '/welfare-center?welfareTab=MyActivities',
  /** 合约交易页面 */
  contractTransfer = '/futures/BTCUSD',
  /** 现货交易页面 */
  spotTransfer = '/trade/BTCUSDT',
  /** 三元期权交易 */
  optionTransfer = '/ternary-option/BTCUSD',
  /** kyc */
  kycAuthorization = '/kyc-authentication-homepage',
  /** 资产 */
  assets = '/assets',
  /** 裂变活动 */
  fissionActivity = '/marketing/fission-activity',
  /** 红包雨活动 */
  redEnvelopeActivity = '/marketing/red-envelope',
}
/** 内链字典值映射 key */
export enum TerminalRouteKeyEnum {
  /** 我的任务 - 成就任务	*/
  missionList = 'mission_list',
  /** 我的任务 - 挑战任务 */
  missionAchievement = 'mission_achievement',
  /** 我的奖励 */
  awardList = 'award_list',
  /** 我的活动 */
  activityProcessing = 'activity_processing',
  /** 合约交易页面 */
  contractTransfer = 'contract_transfer',
  /** 现货交易页面 */
  spotTransfer = 'spot_transfer',
  /** 三元期权交易 */
  optionTransfer = 'option_transfer',
  /** kyc */
  kycAuthorization = 'kyc_authorization',
  /** 资产 */
  assets = 'assets',
  /** 裂变活动 */
  fissionActivity = 'fission_activity',
  /** 红包雨活动 */
  redEnvelopeActivity = 'envelope_activity',
}
