import FullScreenLoading, { IFullScreenLoadingProps } from '@/components/full-screen-loading'
import ReactDOM from 'react-dom'

interface IShowLoadingParmas extends IFullScreenLoadingProps {
  /** 延迟显示loading */
  delay?: number
}

/** loading组件挂载点 */
let mountEl: HTMLDivElement
/** loading总数 */
let loadingCount = 0

/**
 * 创建挂载元素
 */
const createMountEl = () => {
  mountEl = document.createElement('div')
  document.body.appendChild(mountEl)
}

/**
 * 展示loading
 */
const showLoading = ({ delay, ...componentProps }: IShowLoadingParmas = {}) => {
  let timer: NodeJS.Timeout
  if (!mountEl) {
    createMountEl()
  }
  const open = () => {
    ReactDOM.render(<FullScreenLoading {...componentProps} />, mountEl)
  }
  const closeAll = () => {
    loadingCount = 0
    clearTimeout(timer)
    ReactDOM.unmountComponentAtNode(mountEl)
  }
  const close = () => {
    loadingCount -= 1
    if (loadingCount <= 0) {
      closeAll()
    }
  }

  loadingCount += 1
  timer = setTimeout(open, delay ?? 0)

  return { close }
}

export default showLoading
